import { XMarkIcon } from "@heroicons/react/24/outline"
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"
import React from "react"
import { twMerge } from "tailwind-merge"

import { Input } from "./Inputs"

export const Button = ({
  type = "primary",
  size = "small",
  className,
  disabled = false,
  floatingInMobile,
  onClick,
  children
}) => (
  <button
    onClick={onClick}
    className={twMerge(
      "rounded border px-4 font-bold focus:outline-none focus:ring-2 focus:ring-blue",
      type === "secondary"
        ? "border-teal bg-white text-teal active:bg-gray-light"
        : type === "tertiary"
        ? "border-gray bg-white active:bg-gray-light"
        : type === "destructive"
        ? "border-red bg-red text-white"
        : type === "warning"
        ? "border-red bg-white text-red"
        : type === "coming-soon"
        ? "cursor-not-allowed border-gray-ultralight bg-gray-ultralight text-gray-dark"
        : type === "link"
        ? "border-none p-0 text-teal underline hover:shadow-none"
        : "border-teal bg-teal text-white hover:shadow active:bg-teal-dark",
      size === "large" ? "h-12" : "h-8",
      disabled ? "pointer-events-none opacity-25" : "",
      floatingInMobile ? "relative z-10 sm:fixed sm:bottom-[86px] sm:right-[16px]" : "",
      className
    )}>
    {children}
  </button>
)

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "destructive", "warning", "coming-soon", "link"]),
  size: PropTypes.oneOf(["large", "small"]),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
}

export const CloseButton = ({ onClick, active = false, className }) => (
  <button
    className={twMerge(
      "h-8 w-8 rounded text-gray-dark hover:bg-gray-light focus:outline-none focus:ring-2 focus:ring-blue active:bg-gray",
      active ? "bg-gray" : "",
      className
    )}
    onClick={onClick}>
    <XMarkIcon />
  </button>
)

CloseButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool
}

export const CopyLinkButton = ({ onClick, value, className }) => {
  function stripHttp(url) {
    return url.replace(/^(https?:\/\/)/, "")
  }

  const strippedValue = stripHttp(value)

  return (
    <div className={twMerge("relative", className)}>
      <Input disabled={true} value={strippedValue} />
      <Button
        className="absolute right-[3px] top-[3px] flex items-center"
        type="tertiary"
        size="small"
        onClick={onClick}>
        {" "}
        Copy link <DocumentDuplicateIcon className="ml-2 h-5 w-5" />
      </Button>
    </div>
  )
}

CopyLinkButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

export const CircularButton = ({ Icon, className, disabled, ...rest }) => (
  <button
    {...rest}
    disabled={disabled}
    className={twMerge(
      "flex h-8 w-8 items-center justify-center rounded-full border border-gray",
      disabled ? "opacity-30" : "",
      className
    )}>
    <Icon className="h-5 w-5" />
  </button>
)
